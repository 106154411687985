import faker from "@faker-js/faker";
import { registerables } from "chart.js";

export const labels = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const dataSpace = (tempature, humidity, label) => {
  return {
    labels: label,
    datasets: [
      {
        label: "Temperature",
        data: tempature,
        // data: [18, 25, 28, 3, 5, 18],
        borderColor: "#005DAA",
        pointBackgroundColor: "#F9A825",
        pointBorderColor: "white",
        pointHoverRadius: 10,
        pointHoverBackgroundColor: "#F9A825",
        tension: 0.4,
        backgroundColor: "rgba(0, 166, 239, 0.2)",
        fill: true,
        yAxisID: "y",
      },
      {
        label: "RH",
        data: humidity,
        // data: [20, 10, 32, 3, 5, 25],
        pointBackgroundColor: "#F9A825",
        pointBorderColor: "white",
        pointHoverRadius: 10,
        pointHoverBackgroundColor: "#F9A825",
        borderColor: "#00A6EF",
        yAxisID: "percentage",
        tension: 0.4,
        backgroundColor: "rgba(0, 166, 239, 0.2)",
      },
    ],
  };
};

export const plugins = [
  {
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.setLineDash([5, 7]);
        ctx.moveTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#00A6EF";
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];
const titleToolTip = (tooltipItems) => {
  return tooltipItems[0].dataset.label;
};
const labelToolTip = (labelToolItems) => {
  if (labelToolItems.dataset.label == "RH") {
    return labelToolItems.formattedValue + "%";
  }

  return labelToolItems.formattedValue + "°C";
};
export const optionsSpace = {
  elements: {
    point: {
      radius: 0,
    },
  },
  tooltips: {
    enabled: true,
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      // grace: "50",
      grid: {
        display: true,
      },
      ticks: {
        callback: function (value) {
          return value + "°C";
        },
      },
    },
  },
};
export const options = (maxWidth765, maxWidth500, label) => {
  return {
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
    },
    hover: {
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      legend: {
        display: false,
      },
      tooltip: {
        caretPadding: 20,
        yAlign: "bottom",
        titleMarginBottom: 0,
        displayColors: false,
        boxWidth: 0,
        titleFont: {
          size: 13,
        },
        padding: 8,
        bodyFont: {
          size: 25,
          weight: "bolder",
          lineHeight: 1.5,
        },
        bodySpacing: 10,
        callbacks: {
          // beforeTitle: function (context) {
          //   return "Tempature";
          // },

          // beforeBody: function (context) {
          //   let findData = context.find(
          //     (c) =>
          //       c.dataset && c.dataset.label && c.dataset.label === "Temperature"
          //   );
          //   if (findData && findData.formattedValue) {
          //     return findData.formattedValue + "°C";
          //   }
          // },

          // afterBody: function (context) {
          //   // console.log("context", context);
          //   return "+ggg";
          // },
          title: function (labelToolItems) {
            console.log("labelToolItems", labelToolItems);
            return labelToolItems[0].label;
          },
          label: function (labelToolItems) {
            if (labelToolItems.dataset.label == "RH") {
              return "RH: " + labelToolItems.formattedValue + "%";
            }

            return "Temperature: " + labelToolItems.formattedValue + "°C";
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          // maxTicksLimit: maxWidth500 ? 6 : maxWidth765 ? 12 : 24,
          maxRotation: 0,
          minRotation: 0,
          callback: function (value, index, values) {
            if (label[index].toString().indexOf(".") == -1) {
              return label[index];
            }
          },
        },
      },
      y: {
        // stacked: true,
        // grace: "50",
        beginAtZero: true,
        type: "linear",
        position: "left",
        grid: {
          display: true,
        },
        min: 0,
        max: 40,
        ticks: {
          stepSize: 5,
          callback: function (value) {
            return value + "°C";
          },
        },
      },
      percentage: {
        type: "linear",
        position: "right",
        beginAtZero: true,

        grid: {
          display: true,
          drawOnChartArea: false,
        },
        min: 50,
        max: 80,
        ticks: {
          callback: function (value) {
            return value + "%";
            // return ((value / this.max) * 100).toFixed(0) + "%";
          },
        },
      },
    },
  };
};
