import Faker from "@faker-js/faker";

export const labels = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const titleToolTip = (tooltipItems) => {
  return tooltipItems[0].label;
};
const labelToolTip = (labelToolItems) => {
  return labelToolItems.formattedValue + "°C";
};
export const options = (maxWidth765, maxWidth500, label) => {
  return {
    elements: {
      point: {
        radius: 0,
      },
    },

    hover: {
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      legend: {
        display: false,
      },
      tooltip: {
        caretPadding: 20,
        yAlign: "bottom",
        titleMarginBottom: 0,
        displayColors: false,
        boxWidth: 0,
        titleFont: {
          size: 13,
        },
        padding: 8,
        bodyFont: {
          size: 25,
          weight: "bolder",
          lineHeight: 1.5,
        },
        bodySpacing: 10,
        callbacks: {
          title: titleToolTip,
          label: labelToolTip,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          // maxTicksLimit: maxWidth500 ? 6 : maxWidth765 ? 12 : 24,
          maxRotation: 0,
          minRotation: 0,
          callback: function (value, index, values) {
            if (label[index].toString().indexOf(".") == -1) {
              return label[index];
            }
          },
        },
      },

      y: {
        stacked: true,

        // grace: "50",
        beginAtZero: true,
        max: 30,
        min: 0,

        grid: {
          display: true,
        },
        ticks: {
          callback: function (value) {
            return value + "°C";
          },
        },
      },
    },
  };
};
export const data = (data, label) => {
  return {
    labels: label,
    datasets: [
      {
        // label: "Dataset 1",
        // data: labels.map(() => Faker.datatype.number({ min: 0, max: 1500 })),
        data: data,
        // data: [35, 12, 6, 9, 12, 3, 9],
        borderColor: "#00A6EF",
        pointBackgroundColor: "#F9A825",
        pointBorderColor: "white",
        pointHoverRadius: 10,
        pointHoverBackgroundColor: "#F9A825",
        pointHoverBorderColor: "white",
        tension: 0.4,
        backgroundColor: "rgba(0, 166, 239, 0.2)",
        fill: true,
      },
    ],
  };
};

export const plugins = [
  {
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.setLineDash([5, 7]);
        ctx.moveTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#00A6EF";
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];
